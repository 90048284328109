.licence-content {
  width: 70%;
  margin: auto;
  margin-bottom: 50px;
}
.licence-container .col {
  margin: 30px;
  border: solid black 1px;
  border-radius: 4px;
}
.licence-container h4 {
  padding: 10px;
  text-align: center;
}
.licence-container p {
  padding: 10px;
  margin: 0;
}
.tuto img {
  width: 500px;
  height: auto;
}
.tuto {
  margin: 50px 0;
}
.licence-footer p {
  text-align: center;
}
