#contact {
  background: black;
  color: #fff;
  font-size: 14px;
  text-align: center;
  padding: 10px;
  padding-bottom: 30px;
}

#footer .copyright {
  text-align: center;
  margin: 10px;
}
.footer-contact a {
  text-decoration: none !important;
  color: white;
  font-size: 16px;
}
.footer-contact a:hover {
  color: #00d0f5;
  text-decoration: none;
}
.footer-contact {
  margin: 0 20px 0 0;
}
.footer-icons {
  font-size: 50px;
  margin-bottom: 0 20px 0 0;
}
.footer-icons svg {
  color: white;
  background-color: black;
  margin: 20px;
}
.footer-icons svg:hover {
  color: black;
  background-color: white;
  border-radius: 5px;
}
.footer-icons a {
  margin: 0;
  padding: 0;
}
.copyright {
  padding-bottom: 10px;
}
