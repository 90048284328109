.reservation-content {
  width: 70%;
  margin: 30px auto;
  padding: 10px;
  min-height: 50vh;
}
.calendrier {
  justify-content: center;
  margin: 10px;
  margin-bottom: 40px;
}
.calendrier input {
  padding: 5px;
  text-align: center;
}
.reservation-title {
  text-align: center;
  margin: 50px;
}
.reservation-checkbox input {
  margin-right: 10px;
}
.reservation-checkbox {
  margin: 40px auto;
  text-align: left;
  display: inline-block;
}
.reservation-checkbox p {
  text-align: center;
  margin-bottom: 8px;
}
.check-météo {
  text-align: center;
  color: red;
  margin-bottom: 5px;
}

.reservation-contenair {
  text-align: center;
}
.form-control {
  width: 200px;
}
.form-group {
  margin: 40px 0;
}
.reservation-form {
  display: flex;
  justify-content: center;
  margin: -50px;
}
.response {
  color: green;
  font-weight: bolder;
  font-size: larger;
  margin-bottom: 40px;
}
.funkart-text {
  text-align: center;
  margin-bottom: 50px;
}
.funkart-text svg {
  font-size: 30px;
  color: red;
  position: relative;
  bottom: 5px;
}
.button-reservation {
  margin-top: -25px;
  margin-bottom: 50px;
}
.mandatory {
  color: red;
}
.required-error {
  color: red;
}
