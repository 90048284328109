.moncompte-body .row {
  margin: auto;
}

.moncompte-body h3 {
  padding: 20px;
  color: white;
  font-size: 22px;
}
.moncompte-contact {
  width: 100%;
  margin: auto;
  margin-top: 150px;
}
.moncompte-infos {
  text-align: center;
}
.moncompte-infos .row {
  margin: auto;
}
.moncompte-card {
  width: 100%;
  margin: 50px auto;
  text-align: center;
}
.moncompte-card img {
  border: solid 1px black;
}

.tabSelect {
  padding: 20px;
}
.breadcrumb {
  padding: 0.75rem 1rem;
  margin-bottom: 0;
  list-style: none;
  background-color: #0069d9 !important;
  border-radius: 0.25rem;
  color: white;
}
.breadcrumb p {
  margin: 0;
}
.background-col.col {
  background-color: #343a40;
  max-width: 20%;
  min-height: 90vh;
}
.monCompte-nav {
  padding-top: 50px;
}
.title-moncompte h2 {
  color: black;
  margin: 50px auto;
  text-align: center;
}
.display-edit {
  text-align: left;
  border-color: black;
  border-radius: 0.25rem;
  border: 1px solid;
  padding: 20px;
  margin: 0 20px;
}

.display-edit-right {
  text-align: left;
  border-color: black;
  border-radius: 0.25rem;
  border: 1px solid;
  padding: 20px;
  margin: 0 20px;
}

.moncompte-link {
  text-align: center;
  margin: auto;
  text-decoration: none;
  margin: 20px;
}
.moncompte-link:hover {
  text-decoration: none;
}
.display-edit-right input {
  margin-bottom: 15px;
  border: 0;
  outline-style: none;
  border-bottom: solid 1px black;
  width: 100%;
}
.display-edit-right label {
  margin: 0;
}
.contact-form img {
  height: auto;
  width: 300px;
  border: solid 1px black;
}
.contact-form form {
  margin-top: 20px;
}
.contact-form img:hover {
  height: auto;
  width: 700px;
}
.contact-form {
  width: 100%;
  margin: 50px auto;
  text-align: center;
}
.contact-form input {
  width: 400px;
  margin: 10px;
  border: 0;
  padding: 5px;
  border-bottom: solid 1px black;
  outline-style: none;
}
.contact-form textarea {
  width: 400px;
  border-radius: 4px;
  margin: 30px;
  border: 0;
  padding: 5px;
  height: 140px;
  border: solid 1px black;
  outline-style: none;
}
.infos-button {
  margin: 10px;
}
@media screen and (min-width: 500px) and (max-width: 800px) {
  .moncompte-body {
    flex-wrap: nowrap !important;
  }
  .monCompte-nav p {
    display: none;
  }
  .tabSelect {
    padding: 10px;
  }
  .background-col.col {
    background-color: #343a40;
    max-width: 50px;
    min-height: 90vh;
  }
  .moncompte-body h3 {
    padding: 0px;
    margin: 20px 14px;
    color: white;
    font-size: 22px;
  }
  .title-moncompte th {
    font-size: 16px;
    padding: 5px;
  }
  .hide-column {
    display: none;
  }
}
@media screen and (min-width: 380px) and (max-width: 530px) {
  .moncompte-body {
    flex-wrap: nowrap !important;
  }
  .monCompte-nav p {
    display: none;
  }
  .tabSelect {
    padding: 10px;
  }
  .background-col.col {
    background-color: #343a40;
    max-width: 50px;
    min-height: 90vh;
  }
  .moncompte-body h3 {
    padding: 0px;
    margin: 20px 14px;
    color: white;
    font-size: 22px;
  }
  .title-moncompte th {
    font-size: 16px;
    padding: 5px;
  }
  .hide-column {
    display: none;
  }
  .contact-form input {
    width: 250px;
    margin: 10px;
    border: 0;
    padding: 5px;
    border-bottom: solid 1px black;
    outline-style: none;
  }
  .contact-form textarea {
    width: 250px;
    border-radius: 4px;
    margin: 30px;
    border: 0;
    padding: 5px;
    height: 140px;
    border: solid 1px black;
    outline-style: none;
  }
  .contact-form img:hover {
    height: auto;
    width: 300px;
  }
}

@media screen and (min-width: 1px) and (max-width: 380px) {
  .moncompte-body {
    flex-wrap: nowrap !important;
  }
  .monCompte-nav p {
    display: none;
  }
  .tabSelect {
    padding: 10px;
  }
  .background-col.col {
    background-color: #343a40;
    max-width: 50px;
    min-height: 90vh;
  }
  .moncompte-body h3 {
    padding: 0px;
    margin: 20px 14px;
    color: white;
    font-size: 22px;
  }
  .title-moncompte th {
    font-size: 15px;
    padding: 5px;
  }
  .hide-column {
    display: none;
  }
  .moncompte-body .col {
    flex-grow: 0;
  }
  .contact-form input {
    width: 200px;
    margin: 10px;
    border: 0;
    padding: 5px;
    border-bottom: solid 1px black;
    outline-style: none;
  }
  .contact-form textarea {
    width: 200px;
    border-radius: 4px;
    margin: 30px;
    border: 0;
    padding: 5px;
    height: 140px;
    border: solid 1px black;
    outline-style: none;
  }
  .contact-form img {
    height: auto;
    width: 250px;
    border: solid 1px black;
  }
  .contact-form img:hover {
    height: auto;
    width: 300px;
  }
}
