#logo {
  position: absolute;
  width: 200px;
  height: auto;
  top: 15px;
  left: 15px;
}
.header {
  background-color: black;
  height: 100px;
}

#myAccount {
  display: block;
}

/*--------------------------------------------------------------
  # Navigation Menu
  --------------------------------------------------------------*/
/* Nav Menu Essentials */

.nav-menu,
.nav-menu * {
  margin: 0;
  padding: 0;
  list-style: none;
}

.nav-menu ul {
  position: absolute;
  display: none;
  top: 100%;
  left: 0;
  z-index: 99;
}

.nav-menu li {
  position: relative;
  white-space: nowrap;
}

.nav-menu > li {
  float: left;
}

.nav-menu li:hover > ul,
.nav-menu li.sfHover > ul {
  display: block;
}

.nav-menu ul ul {
  top: 0;
  left: 100%;
}

.nav-menu ul li {
  min-width: 180px;
}

/* Nav Meu Container */
#nav-menu-container {
  float: right;
  margin: 0px 10px;
}

/* Nav Menu Styling */
.nav-menu a {
  padding: 0 8px 10px 8px;
  text-decoration: none;
  display: inline-block;
  color: white;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 20px;
  outline: none;
}

.nav-menu > li {
  margin-left: 10px;
}

.nav-menu > li > a:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: red;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out 0s;
}

.nav-menu a:hover:before,
.nav-menu li:hover > a:before,
.nav-menu .menu-active > a:before {
  visibility: visible;
  transform: scaleX(1);
}

.nav-menu ul {
  margin: 4px 0 0 0;
  border: 1px solid white;
}

.nav-menu ul li {
  background: white;
}

.nav-menu ul li:first-child {
  border-top: 0;
}

.nav-menu ul li a {
  padding: 10px;
  transition: 0.3s;
  display: block;
  font-size: 13px;
  text-transform: none;
}

.nav-menu ul li a:hover {
  background: red;
  color: white;
}

.nav-menu ul ul {
  margin: 0;
}
.nav-menu li a {
  color: white;
  text-decoration: none;
}
.nav-menu ul li a {
  font-size: 20px;
  border-left: solid 5px red;
  border-right: solid 5px red;
}
.nav-menu svg {
  margin-right: 2px;
  bottom: 4px;
  position: relative;
  font-size: 30px;
}
.menu-mobile {
  display: none;
}
.menu-mobile-global {
  display: none;
}

.container {
  max-width: 100% !important;
  position: relative;
  top: 30px;
}
.header-form {
  text-align: center;
}

/*
* Modal
*/
#modal-title {
  margin: 0 20px;
}
.modal-footer p {
  text-align: center;
  margin: 10px;
}
.modal-label {
  margin-left: 20px;
  text-align: center;
}
#nom {
  margin-left: 37px;
}
#email {
  margin-left: 34px;
}
#tel {
  margin-left: 53px;
}
#message {
  margin-left: 10px;
}
.modal-label input {
  border: solid #00324f 1px;
  margin: 5px;
  width: 300px;
}
.modal-label textarea {
  border: solid #00324f 1px;
  margin: 5px;
  width: 300px;
}
.message {
  position: relative;
  top: -48px;
}
#button-envoyer {
  text-align: center;
  padding: 3px;
  border-radius: 10px;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
#button-envoyer:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}
#button-close {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
#button-close:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}
.modal-footer {
  display: block !important;
  text-align: center; 
}

#login-modal {
  margin: 0 !important;
  display: flex;
  justify-content: center;
  max-width: 626px;
  position: relative;
  margin: 100px auto !important;
  height: 417px !important;
}
#body-modal {
  
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: auto;
  border-radius: 3px;
}
.nav-link {
  padding: 0 !important;
  display: block;
}

/*
* Responsive
*/
@media screen and (min-width: 1200px) and (max-width: 1800px) {
  .message {
    position: relative;
    top: -31px;
  }
}

@media screen and (min-width: 500px) and (max-width: 1200px) {
  #nav-menu-container {
    display: none;
  }
  .header-nav-background {
    height: 100px;
  }
  .menu-mobile {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    font-size: 40px;
    color: white;
    margin-top: 20px;
    margin-right: 30px;
  }
  .nav {
    flex-direction: column;
    position: absolute;
    top: 0;
    right: 0;
    background-color: black;
    margin-top: 60px;
    border-left: solid 3px red;
    border-radius: 0px;
    z-index: 1000;
  }
  .menu-mobile-global .nav-link {
    text-decoration: none;
    color: white;
    padding: 20px 40px;
    font-size: 20px;
  }
  .menu-mobile-global .nav-link:hover {
    text-decoration: none;
  }
  .menu-mobile-global {
    display: block;
  }
  .nav-menu {
    display: flex;
    flex-direction: column;
    margin: 20px;
  }
  .nav-menu li a {
    margin: 10px 0;
    margin-right: 10px;
  }
  #logo {
    top: 13px;
    left: 30px;
  }
  .message {
    position: relative;
    top: -31px;
  }
}
@media screen and (max-width: 500px) {
  .header {
    background-color: black;
    height: 70px;
  }
  #logo {
    position: absolute;
    width: 100px;
    height: auto;
    top: 16px;
    left: 15px;
  }
  #nav-menu-container {
    display: none;
  }
  .header-nav-background {
    height: 100px;
  }
  .menu-mobile {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    font-size: 40px;
    color: white;
    margin-top: 15px;
    margin-right: 20px;
  }
  .nav {
    flex-direction: column;
    position: absolute;
    top: 0;
    right: 0;
    background-color: black;
    margin-top: 60px;
    border-left: solid 3px red;
    border-radius: 0px;
    z-index: 1000;
  }
  .menu-mobile-global .nav-link {
    text-decoration: none;
    color: white;
    padding: 20px 40px;
    font-size: 20px;
  }
  .menu-mobile-global .nav-link:hover {
    text-decoration: none;
  }
  .menu-mobile-global {
    display: block;
  }
  .nav-menu {
    display: flex;
    flex-direction: column;
    margin: 20px;
  }
  .nav-menu li a {
    margin: 10px 0;
    margin-right: 10px;
  }
  .message {
    position: relative;
    top: 0;
  }
  #nom {
    margin-left: 0px;
  }
  #email {
    margin-left: 0px;
  }
  #tel {
    margin-left: 0px;
  }
  #message {
    margin-left: 0;
  }
  .modal-label input {
    border: solid #00324f 1px;
    margin: 5px;
    width: 100%;
  }
  .modal-label textarea {
    border: solid #00324f 1px;
    margin: 5px;
    width: 100%;
  }
  .modal-label {
    margin-left: 0;
    text-align: center;
  }
  
}
