.content {
  background-color: gray;
  width: 100%;
  height: 90vh;
  overflow: hidden;
}
.content img {
  background-size: cover;
  background-position: center;
}
.carousel-control-prev {
  background-color: red !important;
  width: 50px !important;
}
.carousel-control-next {
  background-color: red !important;
  width: 50px !important;
}
.carousel-indicators li {
  background-color: red !important;
}
.row-ligne .col {
  justify-content: center;
  display: flex;
}
.title {
  text-align: center;
  margin: 50px;
}
.rc-progress-line {
  height: 10px;
  width: 50%;
}
.location-container {
  width: 70%;
  margin: auto;
  margin-bottom: 50px;
  padding: 10px;
}

.column-left p {
  margin: 0;
  font-size: 20px;
  margin-top: 15px;
}
.column-left {
  margin: 30px 0;
  text-align: center;
}
.column-right h3 {
  text-align: center;
  margin-bottom: 20px;
}
.column-right img {
  height: 300px;
  border-radius: 4px;
}
.conditions-container {
  display: flex;
  justify-content: center;
  margin: 30px 0;
}
.conditions-container .conditions,
.licence {
  margin: 20px;
  border: solid black 1px;
  border-radius: 4px;
  padding: 5px;
  width: 500px;
}
.conditions h4 {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  margin: 15px;
}
.conditions p {
  padding: 5px;
}
.licence h4 {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  margin: 15px;
}
.licence p {
  padding: 5px;
}
.licence a {
  text-align: center;
  text-decoration: none;
}
.licence a:hover {
  text-decoration: none;
}
.licence-link {
  text-align: center;
}
.diff-center {
  margin-top: 20px;
  text-align: center;
}
.table-price {
  width: 50%;
  text-align: center;
  margin: 50px auto;
}
.bottom-lign {
  text-align: center;
}
.black {
  color: black;
  font-weight: bold;
}
.button-reservation {
  text-align: center;
  margin-top: 40px;
}
.center-black {
  text-align: center;
  margin-top: 30px;
}
.response-valide {
  color: red;
  font-weight: bolder;
  font-size: larger;
  margin-bottom: 40px;
}

@media screen and (min-width: 1000px) and (max-width: 1300px) {
  .content {
    height: 100%;
  }
}

@media screen and (min-width: 600px) and (max-width: 1000px) {
  .content {
    height: 100%;
  }
  .conditions-container {
    display: block;
  }
  .conditions-container .conditions,
  .licence {
    margin: 20px auto;
    width: auto;
  }
  .row-ligne {
    justify-content: center;
    display: block !important;
  }
  .column-right img {
    margin-bottom: 30px;
  }
  .column-left {
    margin: 30px 0 70px 0;
    text-align: center;
  }
  .title {
    text-align: center;
    margin: 50px 0;
  }
}

@media screen and (max-width: 600px) {
  .content {
    height: 100%;
  }
  .conditions-container {
    display: block;
  }
  .conditions-container .conditions,
  .licence {
    margin: 20px auto;
    width: auto;
  }
  .title {
    text-align: center;
    margin: 50px 0;
  }
  .column-right img {
    margin-bottom: 30px;
  }
  .column-left {
    margin: 30px 0 70px 0;
    text-align: center;
  }
}
