.account-body {
  text-align: center;
  display: flex;
}
.border-none {
  width: 50%;
  border: 0 !important;
}
.border-none-create {
  width: 100%;
  border: 0 !important;
}
.border-none-create input {
  border: 0;
  border-bottom: solid 1px black;
  background-color: transparent;
  margin: 10px;
  outline-style: none;
}
.border-none input {
  border: 0;
  border-bottom: solid 1px black;
  background-color: transparent;
  margin: 10px;
  outline-style: none;
}
.border-none h1 {
  margin: 30px;
}
.border-none-create h1 {
  margin: 30px;
}
.button-login {
  margin: 40px 20px 20px 20px;
}
.button-login-new {
  margin: 70px 20px 20px 20px;
}
