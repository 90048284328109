.admin-content {
  margin: 30px;
}
.admin-table {
  margin-top: 30px;
}
.admin-navbar {
  margin: 10px;
  text-align: center;
}
.listing-client {
  margin: 30px;
}
.red-line {
  color: red;
  margin-bottom: 8px;
}
.margin-table-1 {
  margin-bottom: 8px;
}
.margin-table {
  margin-bottom: 6px;
}
