.occas-content {
  width: 100%;
}
.occas-content h1 {
  text-align: center;
  margin: 50px;
}
.occas-content .row {
  margin-bottom: 100px;
}
.occas-content .col {
  flex-grow: 0;
  margin: 0 auto;
}
.occas-content .card {
  pointer-events: none;
}
.occas-content .card:hover {
  pointer-events: none;
}
.occas-content .card {
  width: 300px !important;
  height: auto !important;
}
.occas-content .card img {
  width: 280px !important;
  height: auto !important;
  margin: 10px auto;
}
