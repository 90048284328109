.accueil-content {
  text-align: center;
  margin: auto;
  height: 100vh;
}
.logo {
  position: absolute;
  top: 20px;
  right: 20px;
  max-width: 300px;
  max-height: auto;
}
.background {
  background-image: url("../../assets/imgReact/5.jpg");
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100vh;
}
.column {
  background-color: rgb(0, 0, 0);
  height: auto;
  max-width: 280px !important;
}
.card-img-top {
  height: 160px !important;
  width: 200px !important;
}
.col a {
  text-decoration: none;
  color: black;
}
.col {
  padding: 0 !important;
}
.row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.card-title {
  margin-bottom: 20px;
}
.card {
  height: 160px !important;
  width: 200px !important;
  margin: 25px 40px;
}
.overlay {
  position: absolute;
  bottom: -1px;
  left: -1px;
  width: 200px;
  height: 40px;
  background: linear-gradient(#ffffffa2, white);
  z-index: 1;
}
.card:hover {
  box-shadow: 5px 5px 5px red;
  position: relative;
  height: 160px !important;
  width: 200px !important;
}
.card-body p {
  text-decoration: none !important;
  margin: 0;
}
.card-body {
  padding: 8px !important;
}
a:hover {
  text-decoration: none !important;
  color: black;
}
.overlay h3 {
  font-weight: bold;
}

@media screen and (min-width: 600px) and (max-width: 700px) {
  .logo {
    position: absolute;
    top: 20px;
    right: 20px;
    max-width: 200px;
    max-height: auto;
  }
}

@media screen and (max-width: 600px) {
  .logo {
    position: absolute;
    top: 20px;
    right: 20px;
    max-width: 200px;
    max-height: auto;
  }
  .card-img-top {
    height: 80px !important;
    width: 100px !important;
  }
  .card {
    height: 80px !important;
    width: 100px !important;
    margin: 25px 40px;
  }
  .card:hover {
    box-shadow: 5px 5px 5px red;
    position: relative;
    height: 80px !important;
    width: 100px !important;
  }
  .overlay {
    position: absolute;
    bottom: -1px;
    left: -1px;
    width: 100px;
    height: 20px;
    background: linear-gradient(#ffffffa2, white);
    z-index: 1;
  }
  .overlay h3 {
    font-size: 16px;
    font-weight: bold;
  }
  .column {
    background-color: black;
    height: auto;
    max-width: 180px !important;
  }
}
